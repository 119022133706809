import React, { useState } from "react"
import Button from "../Button"
import { IconArrowRight, IconChevronDownSmall } from "../Icon"

const NeedMoreInfo = () => {
  const [isExpanded, setExpanded] = useState(false)

  const getClassName = () => (isExpanded ? "expanded" : "collapsed")
  const COLOR_THEME_BLUE = "#001e82"

  return (
    <div className={`needMoreInfo-wrapper ${getClassName()}`}>
      <div className="needMoreInfo-header" onClick={() => setExpanded(!isExpanded)}>
        <h3 className={`title`}>
          Need more information?
          <span className="needMoreInfo-icon-chevron">
            <IconChevronDownSmall stroke={COLOR_THEME_BLUE} width={12} height={12} />
          </span>
        </h3>
      </div>

      <div className="needMoreInfo-content">
        <div className="block">
          <p>Telstra Wholesale customers: Contact your Service Manager: Anthony Churchward</p>
          <Button
            btnState={"active"}
            href="mailto:Anthony.L.Churchward@telstrawholesale.com"
            //href="https://s904576154.t.en25.com/e/f2.aspx?elqFormName=landingpage_contactme&amp;elqSiteID=904576154&amp;emailAddress=~~eloqua..type--emailfield..syntax--EmailAddress..innerText--EmailAddress..encodeFor--url~~&amp;firstName=~~eloqua..type--emailfield..syntax--FirstName..innerText--FirstName..encodeFor--url~~&amp;lastName=~~eloqua..type--emailfield..syntax--LastName..innerText--LastName..encodeFor--url~~&amp;company=~~eloqua..type--emailfield..syntax--Company..innerText--Company..encodeFor--url~~&amp;busPhone=~~eloqua..type--emailfield..syntax--Business_Phone1..innerText--Business_Phone1..encodeFor--url~~&amp;primaryServiceManagerEmail1=~~eloqua..type--emailfield..syntax--Primary_Service_Manager_Email1..innerText--Primary_Service_Manager_Email1..encodeFor--url~~&amp;elqTrackId=41e2c787b4cb4194946a09c56e09bac8"
          >
            Contact me <IconArrowRight />
          </Button>
        </div>
        <div className="block">
          <p className="bold-copy">Not a Telstra Wholesale customer?</p>
          <p>Complete the new customer enquiry form and we'll get back to you.</p>
          <Button btnState={"active"} href="https://www.telstrawholesale.com.au/contact.html">
            Enquiry form <IconArrowRight />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NeedMoreInfo
